import { AttemptLetter } from '../components/game/board/Letter';

export const checkAttempt = (currentAttempt: Array<AttemptLetter>, answer: string): Array<AttemptLetter> => {
  const lowercaseWord = currentAttempt.map(att => att.value).join('').toLowerCase();
  const result: Array<AttemptLetter> = [];

  // TODO: Update letter highlighting
  // for (let i = 0; i < lowercaseWord.length; i++) {
  //   if (lowercaseWord[i] === answer[i]) {
  //     result.push({ value: lowercaseWord[i], result: 'correct' });
  //   } else {
  //     result.push({ value: lowercaseWord[i], result: 'incorrect' });
  //   }
  // }

  // for (let i = 0; i < lowercaseWord.length; i++) {
  //   if (result[i].result === 'correct') continue;
    
  //   if (answer.includes(lowercaseWord[i])) {

  //   } 
  // }



  for (let i = 0; i < lowercaseWord.length; i++) {
    if (lowercaseWord[i] === answer[i]) {
      result.push({ value: lowercaseWord[i], result: 'correct' });
    } else if (answer.includes(lowercaseWord[i])) {
      result.push({ value: lowercaseWord[i], result: 'misplaced' });
    } else {
      result.push({ value: lowercaseWord[i], result: 'incorrect' });
    }
  }

  return result;
};

export const calculateScore = (attemptsCount: number) => {
  switch (attemptsCount) {
    case 1: return 100;
    case 2: return 90;
    case 3: return 75;
    case 4: return 50;
    case 5: return 30;
    case 6: return 20;
    default: return 0;
  }
};