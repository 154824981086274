import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import LoadingButton from '@mui/lab/LoadingButton';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { db } from '../../utils/firebase';
import { collection, addDoc, serverTimestamp, setDoc, doc, query, where, getDocs, getDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signInAnonymously, User } from 'firebase/auth';
import Alert from '@mui/material/Alert';

const Copyright = (props: any) => {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://grouple.app/">
        Grouple
      </Link>
      {' ' + new Date().getFullYear() + '.'}
    </Typography>
  );
}

const theme = createTheme();

const Home = () => {
  const [nameMessage, setNameMessage] = useState<string>();
  const [codeMessage, setCodeMessage] = useState<string>();
  const [name, setName] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>();
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const authUnsubscribe = onAuthStateChanged(auth, u => {
      setUser(u);

      if (!u) {
        signInAnonymously(auth);
      }
    });

    return () => authUnsubscribe();
  }, [auth]);

  const queryGame = async (code: string) => {
    const q = query(
      collection(db, 'games'), 
      where('code', '==', code), 
      where('isActive', '==', true));
    const snapshot = await getDocs(q);
    return snapshot.empty ? undefined : snapshot.docs[0];
  };
  
  const handleCreateClick = async () => {
    if (!user) return;

    setNameMessage(name.trim() ? undefined : 'Name cannot be blank');
    setCodeMessage(code.trim() ? undefined : 'Code cannot be blank');

    if (!name.trim() || !code.trim()) return;

    setIsLoading(true);

    const game = await queryGame(code);

    if (game) {
      setErrorMessage('Code already taken. Please chose a new code.');
      setIsLoading(false);
      return;
    }

    const gameData = { 
      code: code, 
      createdAt: serverTimestamp(), 
      createdBy: user.uid, 
      isActive: true 
    };
    const gameRef = await addDoc(collection(db, 'games'), gameData);
    const playerData = { name: name, createdAt: serverTimestamp() };
    await setDoc(doc(db, 'games', gameRef.id, 'players', user.uid), playerData);
    setIsLoading(false);
    navigate(`${code}`);
  };

  const handleJoinClick = async () => {
    if (!user) return;

    setNameMessage(name.trim() ? undefined : 'Name cannot be blank');
    setCodeMessage(code.trim() ? undefined : 'Code cannot be blank');

    if (!name.trim() || !code.trim()) return;

    setIsLoading(true);

    const game = await queryGame(code);

    if (!game) {
      setErrorMessage('Cannot find game. Please check the code and try again.');
      setIsLoading(false);
      return;
    }

    const docRef = await getDoc(doc(db, 'games', game.id, 'players', user.uid));

    if (!docRef.exists()) {
      const playerData = { name: name, createdAt: serverTimestamp() };
      await setDoc(doc(db, 'games', game.id, 'players', user.uid), playerData);
    }

    setIsLoading(false);
    navigate(`${code}`);
  }

  const mainContent = (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            mt: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <VideogameAssetIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Create Game
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Your Name"
              name="name"
              autoComplete="name"
              autoFocus
              error={nameMessage !== undefined}
              helperText={nameMessage}
              value={name}
              onChange={event => setName(event.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="code"
              label="Code"
              type="text"
              id="code"
              error={codeMessage !== undefined}
              helperText={codeMessage}
              value={code}
              onChange={event => setCode(event.target.value.replace(/\s/g, '').toLowerCase())}
            />
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
              <LoadingButton 
                fullWidth 
                variant="contained" 
                loading={isLoading}
                sx={{ mr: 1 }}
                onClick={handleCreateClick}>
                Create Game
              </LoadingButton>
              <LoadingButton 
                fullWidth 
                variant="contained" 
                loading={isLoading} 
                onClick={handleJoinClick}>
                Join Game
              </LoadingButton>
            </div>
          </Box>
          { errorMessage 
            ? <Alert severity="error" sx={{ mt: 3 }}>{errorMessage}</Alert>
            : null}
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );

  return user !== null ? mainContent : null;
}

export default Home;