import React from 'react';
import Letter from './Letter';
import Stack from '@mui/material/Stack';
import { Attempt } from '../Game';

type RowProps = {
  attempt?: Attempt
}

const Row = (props: RowProps) => {
  const { attempt } = props;
  const cols = [];

  for (let i = 0; i < 5; i++) {
    if (attempt) {
      cols.push(<Letter key={i} chosenLetter={attempt.letters[i]} />);
    // } else if (currentAttempt) {
    //   cols.push(
    //     <Letter key={i} chosenLetter={currentAttempt.length > i ? currentAttempt[i] : undefined} />
    //   );
    } else {
      cols.push(<Letter key={i} />);
    }
  }

  return (
    <Stack 
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
      spacing={0.25}>
      {cols}
    </Stack>
  );
};

export default Row;