import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { DocumentData, DocumentSnapshot } from 'firebase/firestore';

type LobbyProps = {
  players: Array<DocumentSnapshot<DocumentData>>
}

const Lobby = (props: LobbyProps) => {
  const { players } = props;

  const convertCurrentRound = ({ code = '', count = 0, score = 0 }: { code: string, count: number, score: number }) => {
    const correct = code.split('').reduce((total, letter) => total + (letter === 'c' ? 1 : 0), 0);
    const misplaced = code.split('').reduce((total, letter) => total + (letter === 'm' ? 1 : 0), 0);

    return (
      <>
        <span style={{ color: 'green', marginRight: 3 }}>C:{correct}</span>|
        <span style={{ color: 'goldenrod', marginRight: 3, marginLeft: 3 }}>M:{misplaced}</span>|
        <span style={{ marginLeft: 3 }}>Attempts: {count}</span>
        <div style={{ fontWeight: 700 }}>Score: {correct === 5 || count === 6 ? score : '??'}</div>
      </>
    );
  }
  
  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: '100%' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Player</TableCell>
            <TableCell>Current Round</TableCell>
            <TableCell align="right">Total Score</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {players.map(player => (
            <TableRow
              key={player.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {player.get('name')}
              </TableCell>
              <TableCell>
                {convertCurrentRound({ 
                  code: player.get('currentAttemptCode'), 
                  count: player.get('currentAttemptCount'),
                  score: player.get('currentAttemptScore')
                })}
              </TableCell>
              <TableCell align="right">{player.get('score') || 0}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default Lobby;