import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBnb6l-eMj7S5qWtMkqpkdw_kJaU5xliiI",
  authDomain: "grouple-ff027.firebaseapp.com",
  projectId: "grouple-ff027",
  storageBucket: "grouple-ff027.appspot.com",
  messagingSenderId: "258175081005",
  appId: "1:258175081005:web:143dd63b3ccab3439a76a4",
  measurementId: "G-PKQQLM9SG6"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
// export const provider = new GoogleAuthProvider();
// provider.setCustomParameters({ prompt: 'select_account' });