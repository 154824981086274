import * as React from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { getBackgroundColor } from '../../../utils/colors';

export type LetterStatus = 'correct' | 'incorrect' | 'misplaced' | 'invalid' | undefined;

export type AttemptLetter = {
  value: string,
  result: LetterStatus
}

type LetterProps = {
  chosenLetter?: AttemptLetter,
}

const Letter = (props: LetterProps) => {
  const { chosenLetter } = props;
  const backgroundColor = chosenLetter ? getBackgroundColor(chosenLetter.result) : 'black';

  return (
    <Card 
      variant="outlined"
      sx={{ 
        backgroundColor: backgroundColor === 'black' ? 'transparent' : backgroundColor,
        border: backgroundColor === 'black' ? '1px solid black' : 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '75px',
        maxHeight: 'calc(100vw / 5)',
      }}>
      <Typography 
        variant="h3" 
        component="div" 
        sx={{ 
          color: chosenLetter && chosenLetter.result === 'invalid' ? 'red' : 'black' 
        }}>
        {chosenLetter ? chosenLetter.value.toUpperCase() : null}
      </Typography>
    </Card>
  );
}

export default Letter;
