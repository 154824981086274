import { Typography } from '@mui/material';
import { useState, useEffect } from 'react';

type TimerProps = {
  initialSeconds: number,
  onTimerEnd: () => void,
}

const Timer = (props: TimerProps) => {
  const { initialSeconds, onTimerEnd } = props;
  const [seconds, setSeconds] = useState(initialSeconds);
  
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        onTimerEnd();
        clearInterval(myInterval);
      }
    }, 1000);
    
    return () => clearInterval(myInterval);
  });

  const display = {
    minutes: Math.floor(seconds / 60),
    seconds: seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60
  };

  return (
    <Typography 
      variant="h5" 
      component="div" 
      gutterBottom 
      textAlign="center" 
      color={seconds > 59 ? 'black' : 'red'}>
      {display.minutes}:{display.seconds}
    </Typography>
  );
}

export default Timer;