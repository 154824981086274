import { Routes, Route } from 'react-router-dom';
import Home from './components/home/Home'
import NavBar from './components/nav/NavBar';
import Gameroom from './components/gameroom/Gameroom';

function App() {
  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path=":code" element={<Gameroom />} />
      </Routes>
    </div>
  );
}

export default App;
