import React from 'react';
import Button from '@mui/material/Button';
import BackspaceIcon from '@mui/icons-material/Backspace';
import { LetterStatus } from '../board/Letter';
import { getBackgroundColor } from '../../../utils/colors';

type KeyboardKeyProps = {
  letter: string,
  status?: LetterStatus,
  onKeyPress: (key: string) => void,
}

const KeyboardKey = (props: KeyboardKeyProps) => {
  const { letter, status, onKeyPress } = props;
  const backgroundColor = getBackgroundColor(status);

  return (
    <Button 
      variant="contained" 
      size="small" 
      sx={{ 
        backgroundColor: backgroundColor,
        minWidth: 0, 
        padding: 1.5,
        ":focus": { 
          backgroundColor: backgroundColor 
        } 
      }}
      onClick={() => onKeyPress(letter)}>
      {letter !== 'Backspace' ? letter : <BackspaceIcon />}
    </Button>
  );
}

export default KeyboardKey;