import { LetterStatus } from '../components/game/board/Letter';

export const getBackgroundColor = (status?: LetterStatus) => {
  switch (status) {
    case undefined:
    case 'invalid':
      return 'black';
    case 'correct':
      return 'green';
    case 'incorrect':
      return 'gray';
    case 'misplaced':
      return 'goldenrod';
  }
};