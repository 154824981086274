import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import dictionary from '../../../utils/dictionary.json';
import { DocumentSnapshot, DocumentData, updateDoc, serverTimestamp } from 'firebase/firestore';

type WordsmithProps = {
  round: DocumentSnapshot<DocumentData>,
}

const Wordsmith = (props: WordsmithProps) => {
  const { round } = props;
  const [word, setWord] = useState(round.get('word') ?? '');
  const [time, setTime] = useState('5');
  const [errorMessage, setErrorMessage] = useState<string>();
  const [submitting, setSubmitting] = useState(false);

  const disabled = submitting || round.get('word') !== undefined;

  const handleSubmit = async () => {
    if (word.length !== 5) {
      setErrorMessage('Word must be 5 letters');
    } else if (!dictionary.includes(word)) {
      setErrorMessage('Not a valid word');
    } else {
      setErrorMessage(undefined);
      setSubmitting(true);
      await updateDoc(round.ref, { word, time: parseInt(time), startedAt: serverTimestamp() });
      setSubmitting(false);
    }
  }

  return (
    <>
      <Stack 
        spacing={0.5} 
        direction="row" 
        justifyContent="space-between"
        alignItems="fill">
        <FormControl>
          <TextField 
            id="outlined-basic" 
            label="Select 5 letter word" 
            variant="outlined" 
            size="small" 
            value={word}
            onChange={event => setWord(event.target.value.replace(/[^a-zA-Z]+/g, '').toLowerCase())} 
            disabled={disabled} 
            error={errorMessage !== undefined}
            helperText={errorMessage} />
        </FormControl>
        <FormControl>
          <InputLabel id="time-label">Time</InputLabel>
          <Select
            labelId="time-label"
            id="time-select"
            value={time}
            label="Age"
            onChange={event => setTime(event.target.value)}
            size="small"
            disabled={disabled} >
            <MenuItem value={3}>3 min</MenuItem>
            <MenuItem value={5}>5 min</MenuItem>
            <MenuItem value={10}>10 min</MenuItem>
            <MenuItem value={15}>15 min</MenuItem>
          </Select>
        </FormControl>
        <LoadingButton 
          variant="contained" 
          size="small"
          disabled={disabled} 
          onClick={handleSubmit}
          loading={submitting}
          sx={{ ml: 1 }}>
          Submit
        </LoadingButton>
      </Stack>
    </>
  );
}

export default Wordsmith;