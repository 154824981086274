import { useCallback, useEffect, useState } from 'react';
import { 
  DocumentSnapshot, DocumentData, updateDoc, collection, addDoc, serverTimestamp, doc, getDoc, 
  query, orderBy, where, getDocs, runTransaction, arrayUnion 
} from 'firebase/firestore';
import Board from './board/Board';
import Keyboard from './keyboard/Keyboard';
import { AttemptLetter } from './board/Letter';
import { calculateScore, checkAttempt } from '../../utils/answers';
import { User } from 'firebase/auth';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import dictionary from '../../utils/dictionary.json';
import { db } from '../../utils/firebase';
import Wordsmith from './wordsmith/Wordsmith';

export type Attempt = {
  letters: Array<AttemptLetter>,
  isWord: boolean,
  isLocked: boolean
}

type GameProps = {
  round: DocumentSnapshot<DocumentData>
  user: User,
  players: Array<DocumentSnapshot<DocumentData>>
}

const Game = (props: GameProps) => {
  const { round, user, players } = props
  const timeLeft = round.get('startedAt') && round.get('time')
    ? Math.floor(((round.get('startedAt').toDate() as Date).getTime() + 
      (round.get('time') as number) * 60 * 1000 - 
      (new Date()).getTime()) / 1000)
    : -1;
  const isLeader = round.get('leaderId') === user.uid;

  const [attempts, setAttempts] = useState<Array<Attempt>>([]);
  const [guessedLetters, setGuessedLetters] = useState<Array<AttemptLetter>>([]);
  const [answer, setAnswer] = useState<string>('');
  const [isComplete, setIsComplete] = useState<boolean>(
    timeLeft < 0 || (round.get('completedPlayerIds') as Array<string>)?.includes(user.uid) || isLeader
  );
  const [boardPlayerId, setBoardPlayerId] = useState<string>();

  const handleKeyClick = useCallback(async (key: string) => {
    if (answer.length !== 5 || isComplete) return;

    const lowerCaseKey = key.toLowerCase();
    const isEnter = lowerCaseKey === 'enter';
    const isBackspace = lowerCaseKey === 'backspace';
    const currentAttempt = attempts[attempts.length - 1];

    if (!isEnter && !isBackspace && (key.length > 1 || lowerCaseKey === key.toUpperCase())) return;
    if ((isBackspace && currentAttempt.letters.length === 0) || (isEnter && !currentAttempt.isWord)) return;
    if (!isEnter && !isBackspace && currentAttempt.letters.length > 4) return;

    if (isBackspace) {
      setAttempts(oldAttempts => {
        const newAttempts = [...oldAttempts];
        const current = newAttempts[newAttempts.length - 1];
        current.letters.pop();
        current.isWord = false;
        current.letters.forEach(letter => letter.result = undefined);
        return newAttempts;
      });
    } else if (isEnter) {      
      // TODO: Create cloud function to check and return results instead of pulling the word down
      // TODO: Fix counts to be by letter and not position
      const result = checkAttempt(currentAttempt.letters, answer);
      const numCorrect = 
        result.reduce((total: number, letter) => total + (letter.result === 'correct' ? 1 : 0), 0);
      const numMisplaced = 
        result.reduce((total: number, letter) => total + (letter.result === 'misplaced' ? 1 : 0), 0);
      const resultCode = result.map(res => {
        if (res.result === 'correct') return 'c';
        if (res.result === 'misplaced') return 'm';
        else return 'i';
      });
      const score = numCorrect === 5 
        ? calculateScore(attempts.length)
        : 0;

      const attemptData = { 
        word: currentAttempt.letters.map(letter => letter.value).join(''),
        numCorrect: numCorrect,
        numMisplaced: numMisplaced,
        results: resultCode,
        score: score,
        createdAt: serverTimestamp(),
        createdBy: user.uid
      };

      if (numCorrect === 5 || attempts.length === 6) {
        await updateDoc(round.ref, { completedPlayerIds: arrayUnion(user.uid) });
      }
      
      await addDoc(collection(db, round.ref.path, 'attempts'), attemptData);
      const playerRef = doc(db, round.ref.parent.parent!.path, 'players', user.uid);
      const playerDoc = await getDoc(playerRef);

      if (playerDoc.get('currentAttemptCode')) {
        const existingCode: string = playerDoc.get('currentAttemptCode');

        for (let i = 0; i < resultCode.length; i++) {
          if (existingCode.length === i) break;
          if (existingCode[i] === 'c') resultCode[i] = 'c';
          if (existingCode[i] === 'm' && resultCode[i] !== 'c') resultCode[i] = 'm';
        }
      }

      const statusData = { 
        currentAttemptCount: attempts.length, 
        currentAttemptCode: resultCode.join(''),
        currentAttemptScore: score,
      };
      await updateDoc(playerRef, statusData);

      try {
        await runTransaction(db, async (transaction) => {
          const playerDoc = await transaction.get(playerRef);
          if (!playerDoc.exists()) {
            throw new Error('Document does not exist!');
          }
      
          const newScore = (playerDoc.get('score') || 0) + score;
          transaction.update(playerRef, { score: newScore });
        });
        console.log('Transaction successfully committed!');
      } catch (e) {
        console.log('Transaction failed: ', e);
      }

      setAttempts(oldAttempts => {
        const newAttempts = [...oldAttempts];
        const current = newAttempts[newAttempts.length - 1];
        current.letters = [...result];
        current.isLocked = true;
        if (newAttempts.length < 6) newAttempts.push({ letters: [], isWord: false, isLocked: false });
        return newAttempts;
      });

      setGuessedLetters(oldLetters => {
        const newLetters = [...oldLetters];

        result.forEach(letter => {
          const index = oldLetters.findIndex(att => att.value === letter.value);
  
          if (index < 0) {
            newLetters.push({...letter});
          } else if (newLetters[index].result === 'misplaced' && letter.result === 'correct') {
            newLetters[index].result = letter.result;
          }
        });

        return newLetters;
      });
    } else {
      setAttempts(oldAttempts => {
        const newAttempts = [...oldAttempts];
        const current = newAttempts[newAttempts.length - 1];
        current.letters.push({ value: lowerCaseKey, result: undefined });
        current.isWord = current.letters.length === 5 && 
          dictionary.includes(current.letters.map(letter => letter.value).join(''));
        current.letters.forEach(letter => 
          letter.result = !current.isWord && current.letters.length === 5 ? 'invalid' : undefined);
        return newAttempts;
      });
    }
  }, [answer, attempts, isComplete, round.ref, user.uid]);

  // TODO: Determine best way to handle this to not add listener multiple times
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => handleKeyClick(event.key);
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyClick]);

  useEffect(() => {
    const fetchAttempts = async () => {
      const attemptsQuery = query(
        collection(db, round.ref.path, 'attempts'), 
        where('createdBy', '==', boardPlayerId ?? user.uid), 
        orderBy('createdAt', 'asc'));
  
      const snapshot = await getDocs(attemptsQuery);
      const existingAttempts: Array<Attempt> = [];
      const existingGuesses: Array<AttemptLetter> = [];
      
      snapshot.docs.forEach(doc => {
        const word: string = doc.get('word');
        const results: Array<string> = doc.get('results');
        const letters: Array<AttemptLetter> = [];

        word.split('').forEach((letter, index) => {
          const attLetter: AttemptLetter = { 
            value: letter, 
            result: results[index] === 'c' 
              ? 'correct' 
              : results[index] === 'm' ? 'misplaced' : 'incorrect'
          };

          letters.push(attLetter);
          const foundIndex = existingGuesses.findIndex(guess => guess.value === attLetter.value);

          if (foundIndex < 0) {
            existingGuesses.push({...attLetter});
          } else if (
            existingGuesses[foundIndex].result === 'misplaced' && attLetter.result === 'correct'
          ) {
            existingGuesses[foundIndex].result = attLetter.result;
          }
        });

        existingAttempts.push({
          letters: letters,
          isWord: true,
          isLocked: true
        })
      });

      if (existingAttempts.length < 6) {
        existingAttempts.push({ letters: [], isWord: false, isLocked: false });
      }

      setAttempts(existingAttempts);
      setGuessedLetters(existingGuesses);
    };

    fetchAttempts();
  }, [boardPlayerId, round.ref.path, user.uid]);

  useEffect(() => {
    setIsComplete((round.get('completedPlayerIds') as Array<string>)?.includes(user.uid) || timeLeft <= 0 || isLeader);
  }, [attempts, round, timeLeft, user.uid, isLeader]);

  // TODO: Remove this effect because won't need word anymore once checking on the server
  useEffect(() => {
    setAnswer(round.get('word') ? round.get('word') : '');
  }, [round]);

  const gameBoard = round.get('word') 
    ? (
      <>
        <Board 
          attempts={attempts} 
          timerSeconds={timeLeft > 0 ? timeLeft : 0}
          players={players.filter(player => player.id !== round.get('leaderId'))} 
          onPlayerIdSelect={playerId => setBoardPlayerId(playerId)}
          showPlayers={isLeader || isComplete}
          onTimerEnd={() => setIsComplete(true)} />
        { !isLeader 
          ? <Keyboard onKeyPress={handleKeyClick} guessedLetters={guessedLetters} /> 
          : null }
      </>
    ) : (
      <div 
        style={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'center', 
          flexDirection: 'column',
          marginTop: 10
        }}>
        <Typography variant="h6" component="div" gutterBottom>
          Waiting for word
        </Typography>
        <CircularProgress color="inherit" />
      </div>
    );
  
  return (
    <div>
      { isLeader ? <Wordsmith round={round} /> : null }
      { gameBoard }
    </div>
  );
}

export default Game;