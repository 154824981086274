import React from 'react';
import Stack from '@mui/material/Stack';
import KeyboardKey from './KeyboardKey';
import { AttemptLetter } from '../board/Letter';

type KeyboardProps = {
  guessedLetters: Array<AttemptLetter>
  onKeyPress: (key: string) => void
}

const Keyboard = (props: KeyboardProps) => {
  const { guessedLetters, onKeyPress } = props;
  const topRow = ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'];
  const middleRow = ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'];
  const bottomRow = ['Enter', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', 'Backspace'];
  const rows = [topRow, middleRow, bottomRow];

  return (
    <Stack 
      alignItems="center" 
      mt={5}
      mb={5}
      sx={{ 
        backgroundColor: 'lightgray',
      }}>
      {rows.map((row, index) => 
        <Stack 
          direction="row" 
          spacing={0.5} 
          alignItems="center" 
          justifyItems="center"
          minWidth={0}
          key={index}
          sx={{ mb: 0.5 }}>
          {
            row.map(key => {
              const existingLetter = 
                guessedLetters.find(letter => letter.value.toLowerCase() === key.toLowerCase());

              return <KeyboardKey 
                letter={key} 
                key={key} 
                onKeyPress={(key: string) => onKeyPress(key)} 
                status={existingLetter?.result} />;
            })
          }
        </Stack>
      )}
    </Stack>
  );
}

export default Keyboard;