import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { getAuth, onAuthStateChanged, signInAnonymously, User } from 'firebase/auth';
import { 
  addDoc, collection, deleteField, doc, DocumentData, DocumentSnapshot, getDocs, onSnapshot, orderBy, query, 
  serverTimestamp, setDoc, updateDoc, where 
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { db } from '../../utils/firebase';
import Game from '../game/Game';
import Lobby from '../lobby/Lobby';

const Gameroom = () => {
  const { code } = useParams();
  const navigate = useNavigate();
  const auth = getAuth();
  const [user, setUser] = useState<User | null>(null);
  const [game, setGame] = useState<DocumentSnapshot<DocumentData>>();
  const [players, setPlayers] = useState<Array<DocumentSnapshot<DocumentData>>>([]);
  const [rounds, setRounds] = useState<Array<DocumentSnapshot<DocumentData>>>([]);
  const [selectedPlayerId, setSelectedPlayerId] = useState<string>('');
  const [isRoundInProgress, setIsRoundInProgress] = useState<boolean>(false);
  const [isCreatingRound, setIsCreatingRound] = useState<boolean>(false);
  const [hasPlayer, setHasPlayer] = useState<boolean>(true);
  const [nameMessage, setNameMessage] = useState<string>();
  const [newPlayerName, setNewPlayerName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getGame = async () => {
      if (!code) {
        navigate('/', { replace: true });
        return;
      }

      const snapshot = await getDocs(query(collection(db, 'games'), where('code', '==', code)));

      if (snapshot.empty) {
        navigate('/', { replace: true });
      } else {
        setGame(snapshot.docs[0]);
      }
    };

    getGame();
  }, [code, navigate]);

  useEffect(() => {
    const authUnsubscribe = onAuthStateChanged(auth, u => {
      setUser(u);

      if (!u) {
        signInAnonymously(auth);
      }
    });

    return () => authUnsubscribe();
  }, [auth]);

  useEffect(() => {
    if (!game) return;

    const playersQuery = query(collection(db, 'games', game.id, 'players'));
    const playersUnsubscribe = onSnapshot(playersQuery, snapshot => {
      if (snapshot.empty) return;

      let docs = snapshot.docs;
      const currentPlayer = docs.find(player => player.id === user?.uid);
      setHasPlayer(currentPlayer !== undefined);
      
      if (currentPlayer) {
        docs = docs.filter(player => player.id !== currentPlayer.id);
        docs.unshift(currentPlayer);
      }

      setPlayers(docs);
      setSelectedPlayerId(oldPlayerId => oldPlayerId === '' ? snapshot.docs[0].id : oldPlayerId);
    });

    const roundsQuery = 
      query(collection(db, 'games', game.id, 'rounds'), orderBy('createdAt', 'desc'));
    const roundsUnsubscribe = onSnapshot(roundsQuery, snapshot => {
      if (snapshot.empty) return;

      setRounds(snapshot.docs);
    });

    return () => {
      playersUnsubscribe();
      roundsUnsubscribe();
    }
  }, [game, user?.uid]);

  useEffect(() => {
    if (rounds.length === 0) return;

    setIsRoundInProgress(rounds[0].get('isActive'));
  }, [rounds]);

  const handleStartRound = async () => {
    if (!game || !user) return;

    setIsCreatingRound(true);
    const roundData = { createdAt: serverTimestamp(), leaderId: selectedPlayerId, isActive: true };
    await addDoc(collection(db, 'games', game.id, 'rounds'), roundData);
    setIsCreatingRound(false);
  }

  const handleEndRound = async () => {
    await updateDoc(rounds[0].ref, { isActive: false });
    players.forEach(player => {
      updateDoc(player.ref, { 
        currentAttemptCode: deleteField(), 
        currentAttemptCount: deleteField(),
      });
    });
  }

  const playerSelect = (
    <div style={{ marginTop: 10, marginBottom: 10 }}>
      <FormControl fullWidth>
        <InputLabel id="playerSelectLabel">Select Player</InputLabel>
        <Select
          id="playerSelect"
          labelId="playerSelectLabel"
          value={selectedPlayerId}
          label="Select Player"
          autoWidth
          onChange={event => setSelectedPlayerId(event.target.value)}
          disabled={isRoundInProgress}
          sx={{ mb: 2 }}>
          {players.map(player => (
            <MenuItem value={player.id} key={player.id}>{player.get('name')}</MenuItem>
          ))}
        </Select>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button 
            variant="contained" 
            fullWidth 
            disabled={isCreatingRound || isRoundInProgress || players.length < 2} 
            onClick={handleStartRound} 
            sx={{ mr: isRoundInProgress ? 1 : 0 }}>
            Start Round
          </Button>
          { isRoundInProgress 
            ? <Button variant="contained" fullWidth onClick={handleEndRound}>
                End Round
              </Button>
            : null }
        </div>
      </FormControl>
    </div>
  );

  const handlePlayerCreate = async () => {
    if (!user || !game) return;

    setNameMessage(newPlayerName.trim() ? undefined : 'Name cannot be blank');

    if (!newPlayerName.trim()) return;

    setIsLoading(true);
    const playerData = { name: newPlayerName, createdAt: serverTimestamp() };
    await setDoc(doc(db, 'games', game.id, 'players', user.uid), playerData);
    setIsLoading(false);
  }

  const playerCreateDialog = (
    <Dialog open={!hasPlayer} onClose={handlePlayerCreate}>
      <DialogTitle>Join Game</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter your name to be used in the game.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Your Name"
          type="name"
          fullWidth
          variant="standard"
          error={nameMessage !== undefined}
          helperText={nameMessage}
          value={newPlayerName}
          onChange={event => setNewPlayerName(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={handlePlayerCreate} loading={isLoading}>Join</LoadingButton>
      </DialogActions>
    </Dialog>
  );

  return user && game 
    ? <>
        {playerCreateDialog}
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12} md={7} lg={8}>
            { isRoundInProgress ? <Game round={rounds[0]} user={user} players={players} /> : null }
          </Grid>
          <Grid item xs={12} md={5} lg={4}>
            <Typography variant="h6" component="div" gutterBottom textAlign="center">
              Code: {game.get('code')}
            </Typography>
            { user.uid === game.get('createdBy') ? playerSelect : null }
            <Lobby players={players} />
          </Grid>
        </Grid>
      </>
    : null;
}

export default Gameroom;