import { Chip } from '@mui/material';
import { DocumentSnapshot, DocumentData } from 'firebase/firestore';
import { useState } from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';

type PlayerPillsProps = {
  players: Array<DocumentSnapshot<DocumentData>>,
  onPlayerSelect: (playerId: string) => void,
}

const PlayerPills = (props: PlayerPillsProps) => {
  const { players, onPlayerSelect } = props;
  const [selectedPlayerId, setSelectedPlayerId] = useState<string>();

  const handlePlayerClick = (playerId: string) => {
    setSelectedPlayerId(playerId);
    onPlayerSelect(playerId);
  }

  return (
    <div style={{ marginTop: 10, marginBottom: 10 }}>
      <ScrollMenu>
        {players.map(player => (
          <Chip 
            key={player.id}
            color="primary"
            label={player.get('name')} 
            onClick={() => handlePlayerClick(player.id)} 
            variant={player.id === selectedPlayerId ? 'filled' : 'outlined'} 
            sx={{ mr: 1 }}/>
        ))}
      </ScrollMenu>
    </div>
  );
}

export default PlayerPills;