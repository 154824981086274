import Row from './Row';
import { Attempt } from '../Game';
import Timer from '../timer/Timer';
import PlayerPills from '../wordsmith/PlayerPills';
import { DocumentSnapshot, DocumentData } from 'firebase/firestore';

type BoardProps = {
  attempts?: Array<Attempt>,
  timerSeconds?: number,
  players: Array<DocumentSnapshot<DocumentData>>,
  onPlayerIdSelect: (playerId: string) => void,
  showPlayers: boolean,
  onTimerEnd?: () => void
}

const Board = (props: BoardProps) => {
  const { attempts, timerSeconds, players, onPlayerIdSelect, showPlayers, onTimerEnd } = props;

  const handlePlayerIdSelect = (playerId: string) => {
    onPlayerIdSelect(playerId);
  }

  return (
    <>
      { showPlayers 
        ? <PlayerPills players={players} onPlayerSelect={handlePlayerIdSelect} />
        : null}
      { timerSeconds !== undefined
        ? <Timer initialSeconds={timerSeconds} onTimerEnd={onTimerEnd ?? (() => {})} />
        : null}
      {[...Array(6)].map((e, i) => (
        <div style={{ marginBottom: 2 }} key={i}>
          <Row attempt={attempts ? attempts[i] : undefined} />
        </div>
      ))}
    </>
  );
}

export default Board;